import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21NoValue } from 'app/shared/u21-ui/components/display/U21NoValue';
import {
  U21ShowMoreList,
  U21ShowMoreListProps,
} from 'app/shared/u21-ui/components/display/U21ShowMoreList';
import { addMinutes } from 'date-fns';
import { forwardRef, ForwardedRef, HTMLProps, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21ExternalLinkModal } from 'app/shared/u21-ui/components/display/U21ExternalLinkModal';
import { U21JsonViewer } from 'app/shared/u21-ui/components/display/U21JsonViewer';
import { U21Modal } from 'app/shared/u21-ui/components/layout/modal/U21Modal';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { getDOMProps } from 'app/shared/utils/react';
import {
  DATE_FORMAT_OPTIONS,
  TIME_FORMAT_OPTIONS,
} from 'app/shared/utils/date';

export enum U21DataDisplayVariant {
  ANY = 'ANY',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  ENUM = 'ENUM',
  LINK = 'LINK',
  LIST = 'LIST',
  NUMBER = 'NUMBER',
  JSON = 'JSON',
  TEXT = 'TEXT',
}

export interface U21DataDisplayBooleanProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.BOOLEAN}`;
  value?: boolean | null;
}

export interface U21DataDisplayEnumProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.ENUM}`;
  value?: string | null;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayNumberProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.NUMBER}`;
  value: number;
  options?: Intl.NumberFormatOptions;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayDateProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.DATE}`;
  value?: string | Date | null;
  options?: Intl.DateTimeFormatOptions;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayDatetimeProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.DATE_TIME}`;
  value?: string | null;
  options?: Intl.DateTimeFormatOptions;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayTextProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.TEXT}`;
  value?: string | number | null;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayListProps<TListItem>
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.LIST}`;
  value?: TListItem[] | null;
  componentProps?: Omit<
    U21ShowMoreListProps<TListItem>,
    'as' | 'ref' | 'value'
  >;
}

export interface U21DataDisplayLinkProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant: `${U21DataDisplayVariant.LINK}`;
  value?: string | null;
  hyperlink: string | null;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayAnyProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  variant?: `${U21DataDisplayVariant.ANY}`;
  value: unknown;
  componentProps?: Omit<U21TypographyProps, 'children' | 'ref'>;
}

export interface U21DataDisplayObjectProps
  extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  title?: string;
  subtitle?: string;
  variant: `${U21DataDisplayVariant.JSON}`;
  value: object;
}

export type U21DataDisplayProps<TListItem> =
  | U21DataDisplayBooleanProps
  | U21DataDisplayEnumProps
  | U21DataDisplayNumberProps
  | U21DataDisplayDateProps
  | U21DataDisplayDatetimeProps
  | U21DataDisplayTextProps
  | U21DataDisplayListProps<TListItem>
  | U21DataDisplayLinkProps
  | U21DataDisplayObjectProps
  | U21DataDisplayAnyProps;

const U21DataDisplayInner = <TListItem,>(
  props: U21DataDisplayProps<TListItem>,
  // any is needed because different rendering conditions have different HTMLElements
  ref: ForwardedRef<any>,
) => {
  const { variant } = props;
  const [jsonPreview, setJsonPreview] = useState<boolean>(false);

  switch (variant) {
    case 'BOOLEAN': {
      const { value, variant: ignore, ...rest } = props;
      if (value === null || value === undefined) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }
      return (
        <U21Typography
          ref={ref}
          variant="body2"
          color={value ? 'success' : 'error'}
          {...getDOMProps(rest)}
        >
          {value ? 'True' : 'False'}
        </U21Typography>
      );
    }
    case 'ENUM': {
      const { componentProps, value, variant: ignore, ...rest } = props;
      if (!value) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }
      return (
        <U21Typography
          ref={ref}
          variant="body2"
          {...componentProps}
          {...getDOMProps(rest)}
        >
          {value}
        </U21Typography>
      );
    }
    case 'NUMBER': {
      const {
        componentProps,
        options,
        value,
        variant: ignore,
        ...rest
      } = props;
      if (value === null || value === undefined) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }
      return (
        <U21Typography
          ref={ref}
          variant="body2"
          {...componentProps}
          {...getDOMProps(rest)}
        >
          {new Intl.NumberFormat('en-US', options).format(value)}
        </U21Typography>
      );
    }
    case 'DATE': {
      const {
        componentProps,
        options,
        value,
        variant: ignore,
        ...rest
      } = props;
      if (!value) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }

      const date = new Date(value);
      if (isNaN(Number(date))) {
        return (
          <U21Typography
            ref={ref}
            variant="body2"
            {...componentProps}
            {...getDOMProps(rest)}
          >
            Invalid date
          </U21Typography>
        );
      }

      // Offset timezone since we ingest any date as midnight UTC
      // so we need to remove the timezone that's added when we convert to date
      const offset = date.getTimezoneOffset();
      const dateOffset = addMinutes(date, offset);
      return (
        <U21Typography
          ref={ref}
          variant="body2"
          {...componentProps}
          {...getDOMProps(rest)}
        >
          {new Intl.DateTimeFormat('en-US', {
            ...DATE_FORMAT_OPTIONS,
            ...options,
          }).format(dateOffset)}
        </U21Typography>
      );
    }
    case 'DATE_TIME': {
      const {
        componentProps,
        options,
        value,
        variant: ignore,
        ...rest
      } = props;
      if (!value) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }

      const date = new Date(value);
      if (isNaN(Number(date))) {
        return (
          <U21Typography
            ref={ref}
            variant="body2"
            {...componentProps}
            {...getDOMProps(rest)}
          >
            Invalid date
          </U21Typography>
        );
      }
      return (
        <U21Typography
          ref={ref}
          variant="body2"
          {...componentProps}
          {...getDOMProps(rest)}
        >
          {new Intl.DateTimeFormat('en-US', {
            ...DATE_FORMAT_OPTIONS,
            ...TIME_FORMAT_OPTIONS,
            ...options,
          }).format(date)}
        </U21Typography>
      );
    }
    case 'TEXT': {
      const { componentProps, value, variant: ignore, ...rest } = props;
      if (!value) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }

      return (
        <U21Typography
          ref={ref}
          variant="body2"
          {...componentProps}
          {...getDOMProps(rest)}
        >
          {value}
        </U21Typography>
      );
    }
    case 'LIST': {
      const { componentProps, value, variant: ignore, ...rest } = props;
      if (value === null || value === undefined || !value.length) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }
      const onClick: U21ShowMoreListProps<string>['onClick'] = (e) => {
        componentProps?.onClick?.(e);
        e.stopPropagation();
      };
      return (
        <StyledShowMoreList
          ref={ref}
          value={value}
          {...componentProps}
          {...getDOMProps(rest)}
          onClick={onClick}
        />
      );
    }

    case 'JSON': {
      const { title, subtitle, value, variant: ignore, ...rest } = props;
      if (value === null || value === undefined || !Object.keys(value)) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }

      return (
        <JsonWrapper
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={ref}
          {...getDOMProps(rest)}
        >
          <U21Modal
            open={jsonPreview}
            onClose={(e) => {
              e.stopPropagation();
              setJsonPreview(false);
            }}
            title={title}
            subtitle={subtitle}
          >
            <U21JsonViewer data={value} />
          </U21Modal>
          <U21Typography component="div" variant="caption">
            <U21JsonViewer
              data={value}
              displayDataTypes={false}
              maxDisplayLength={1}
              collapseStringsAfterLength={20}
              defaultInspectDepth={0} // Start this collapsed
            />

            <div>
              <U21Button
                variant="text"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setJsonPreview(true);
                }}
              >
                Show full object
              </U21Button>
            </div>
          </U21Typography>
        </JsonWrapper>
      );
    }

    case 'LINK': {
      const {
        componentProps,
        hyperlink,
        value,
        variant: ignore,
        ...rest
      } = props;
      if (!value) {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }

      // hyperlink is null if an invalid hyperlink is passed in
      if (hyperlink === null) {
        return (
          <U21Typography
            ref={ref}
            variant="body2"
            {...componentProps}
            {...getDOMProps(rest)}
          >
            <U21Button
              color="primary"
              variant="text"
              disabled
              tooltip="Invalid URL"
            >
              {value}
            </U21Button>
          </U21Typography>
        );
      }
      return (
        <U21ExternalLinkModal url={hyperlink}>
          {({ onClick }) => (
            <U21Typography ref={ref} variant="body2" {...componentProps}>
              <U21Button
                color="primary"
                variant="text"
                onClick={(event) => {
                  onClick();
                  event.stopPropagation();
                }}
              >
                {value}
              </U21Button>
            </U21Typography>
          )}
        </U21ExternalLinkModal>
      );
    }
    default: {
      const { componentProps, value, variant: dd, ...rest } = props;
      if (value === null || value === undefined || value === '') {
        return <U21NoValue ref={ref} {...getDOMProps(rest)} />;
      }
      return (
        <U21Typography
          ref={ref}
          variant="body2"
          {...componentProps}
          {...getDOMProps(rest)}
        >
          {JSON.stringify(value)}
        </U21Typography>
      );
    }
  }
};

export const U21DataDisplay = forwardRef(U21DataDisplayInner);

const StyledShowMoreList = styled(U21ShowMoreList)`
  width: 100%;
  overflow: hidden;
`;

const JsonWrapper = styled(U21Spacer)`
  padding: 16px;
  ${(props) => css`
    background-color: ${props.theme.palette.background.neutral};
    border-radius: ${props.theme.shape.borderRadius}px;
  `}
`;
